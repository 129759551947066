import { CountrySelector } from "@secondstaxorg/sscomp"
import { use } from "i18next"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { axiosSend } from "../utils/request"

interface BankListsProps {
    selectedBank:(bank:{bankName:string, bankCode:string, swiftCode?:string}|null)=>void
    selectedCountry:(bank:string)=>void
    swiftCode?:string
}



const BankLists = (props:BankListsProps) => {
    const { selectedBank, selectedCountry, swiftCode } = props
    const { t } = useTranslation('common')
    const [countryErr, setCountryErr] = useState(false)
    const [country, setCountry] = useState('')
    const [bank, setBank] = useState('')
    const [countries, setCountries] = useState([])
    const [banks, setBanks] = useState([])
    const [bankCode, setBankCode] = useState('')
    const [swift, setSwift] = useState('')
    const [banksOri, setBanksOri] = useState([])


    useEffect(() => {

        //get country
        let tmp:any = []
        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name && country.cty_upd_rem && country.cty_code !== 'ZA'){
                        tmp.push({
                            optionName:country.cty_name,
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })
                let tmp:any = []
                bnks.forEach((bank:any) => {
                    tmp.push({
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    })
                })
                setBanks(tmp)
                setBanksOri(tmp)
            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(()=>{
        if (country) {
            const filteredBanks = banksOri.filter((bank:any) => bank.bankCountry === country)
            setBanks(filteredBanks)
            setBank('')
            selectedBank(null)
        }
    }, [country])


    return (
        <>
            <div className="flex flex-col gap-[8px]">
                <CountrySelector options={countries} selectedOption={(e) => { 
                    setCountry(e.optionShortName) 
                    selectedCountry(e.optionShortName)}} placeholder={t('common:liquidity_providers.select_country')} label={t('common:general.country')} inputName="country" />
                {countryErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500" /> {t('common:general.this_field_required')}</p>}
            </div>

            <CountrySelector options={country ? banks : []} initialCountry={bank} required selectedOption={(a) => {
                const {optionName, optionShortName, swiftCode} = a
                selectedBank({bankName:optionName, bankCode:optionShortName, swiftCode})
                setBank(optionName)
                setBankCode(optionShortName)
                a.swiftCode ? setSwift(a.swiftCode) : setSwift('')
            }} placeholder={t('common:liquidity_providers.select_bank')} label={t('common:general.bank')} inputName="bank" />
        </>
    )
}

export default BankLists;