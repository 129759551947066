import React from 'react';
import './App.css';
import {Routes, Route} from "react-router-dom";
import Login from "./pages/login";
import ForgotPassword from "./pages/forgot-password";
import PasswordReset from "./pages/password-reset";
import Dashboard from "./pages/dashboard";
import Report from "./pages/report";
import TradingHistory from "./pages/trading-history";
import PaymentHistory from "./pages/payment-history";
import PaymentRedirect from "./pages/payment-redirect";
import Trade from "./pages/trade";
import {UserContextProvider} from "./utils/userContext";
import Users from "./pages/users";
import Brokers from "./pages/brokers";
import AppActions from "./pages/app-actions";
import AppForms from "./pages/app-forms";
import AppModules from "./pages/app-modules";
import CurrencyPairs from "./pages/currency-pairs";
import PaymentMethods from "./pages/payment-methods";
import SystemConfig from "./pages/system-config";
import UserRoles from "./pages/user-roles";
import LiquidityProviders from "./pages/liquidity-providers";
import NumBrokerTransactions from "./pages/reports/num-broker-transactions";
import BrokerTransactionsList from "./pages/reports/broker-transactions-list";
import LpTransactionsList from "./pages/reports/lp-transactions-list";
import NumLpTransactions from "./pages/reports/num-lp-transactions";
import PageNotFound from "./pages/PageNotFound";
import SumLpTransactionsByCurr from "./pages/reports/sum-lp-transactions-by-curr";
import SumBrokerTransactionsByCurr from "./pages/reports/sum-broker-transactions-by-curr";
import TrxListByClientId from "./pages/reports/transactions-list-by-client-id";
import AvgLpTrxTime from "./pages/reports/avg-lp-trx-time";
import NumBrokerTrxStatus from "./pages/reports/num-broker-status-trx";
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";
import common_en from './translations/en/common.json'
import common_fr from './translations/fr/common.json'
import Issuers from "./pages/issuers";
import Intermediaries from "./pages/intermediaries";

let locale:string = localStorage.getItem('userLang') || navigator.language.slice(0,2)

//set the html lang attribute to the selected locale
document.documentElement.setAttribute('lang',locale)

i18next.init({
    interpolation:{
        escapeValue:false
    },
    lng: locale,
    fallbackLng: "en",
    resources: {
        en: {
            common: common_en
        },
        fr: {
            common: common_fr
        },
    },
})

function App() {
  return (
      <UserContextProvider>
          <I18nextProvider i18n={i18next}>
              <Routes>
                  <Route path="/" element={<Login/>}/>
                  <Route path="/forgot-password" element={<ForgotPassword/>}/>
                  <Route path="/set-password" element={<PasswordReset/>}/>
                  <Route path="/dashboard" element={<Dashboard/>}/>
                  <Route path="/reports" element={<Report/>}/>
                  <Route path="/reports/num-broker-trx" element={<NumBrokerTransactions/>}/>
                  <Route path="/reports/num-lp-trx" element={<NumLpTransactions/>}/>
                  <Route path="/reports/broker-trx-list" element={<BrokerTransactionsList/>}/>
                  <Route path="/reports/lp-trx-list" element={<LpTransactionsList/>}/>
                  <Route path="/reports/sum-lp-trx-by-base-curr" element={<SumLpTransactionsByCurr/>}/>
                  <Route path="/reports/sum-broker-trx-by-base-curr" element={<SumBrokerTransactionsByCurr/>}/>
                  <Route path="/reports/trx-list-by-client-id" element={<TrxListByClientId/>}/>
                  <Route path="/reports/avg-lp-trx-time" element={<AvgLpTrxTime/>}/>
                  <Route path="/reports/num-broker-trx-by-status" element={<NumBrokerTrxStatus/>}/>
                  <Route path="/transaction-history" element={<TradingHistory/>}/>
                  <Route path="/payment-history" element={<PaymentHistory/>}/>
                  <Route path="/payment-redirect" element={<PaymentRedirect/>}/>
                  <Route path="/request-lp" element={<Trade/>}/>
                  <Route path="/users" element={<Users/>}/>
                  <Route path="/brokers" element={<Brokers/>}/>
                  <Route path="/issuers" element={<Issuers/>}/>
                  <Route path="/intermediaries" element={<Intermediaries/>}/>
                  <Route path="/app-actions" element={<AppActions/>}/>
                  <Route path="/app-forms" element={<AppForms/>}/>
                  <Route path="/app-modules" element={<AppModules/>}/>
                  <Route path="/currency-pairs" element={<CurrencyPairs/>}/>
                  <Route path="/payment-methods" element={<PaymentMethods/>}/>
                  <Route path="/system-config" element={<SystemConfig/>}/>
                  <Route path="/user-roles" element={<UserRoles/>}/>
                  <Route path="/liquidity-providers" element={<LiquidityProviders/>}/>
                  <Route path="*" element={<PageNotFound/>}/>
              </Routes>
          </I18nextProvider>
      </UserContextProvider>
  );
}

export default App;
