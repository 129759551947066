import { useEffect, useState } from "react";
import { axiosSend } from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import { useTranslation } from "react-i18next";
import BankLists from "../BankLists";

interface AddAccountProps {
    entityId: string
}

const AddBankAccount = (props: AddAccountProps) => {
    const { entityId } = props
    const [currencies, setCurrencies]: any = useState([])
    const [country, setCountry] = useState('')
    const [bank, setBank] = useState('')
    const [bankCode, setBankCode] = useState('')
    const [branch, setBranch] = useState('')
    const [acctNo, setAcctNo] = useState('')
    const [acctName, setAcctName] = useState('')
    const [currency, setCurrency] = useState('')
    const [swift, setSwift] = useState('')
    const [address, setAddress] = useState('')
    const [mainAcct, setMainAcct] = useState('Y')
    const [loading, setLoading] = useState(false)
    const [countryErr, setCountryErr] = useState(false)
    const [currencyErr, setCurrencyErr] = useState(false)
    const [resp, setResp]: any = useState({})
    const [stage, setStage] = useState(1)
    const { t } = useTranslation('common')
    const mainAcctOpts = [
        {
            label: t('common:general.yes'),
            value: 'Y'
        },
        {
            label: t('common:general.no'),
            value: 'N'
        }
    ]

    useEffect(() => {
        axiosSend({
            url: '/fx/lov/currency',
            method: 'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC') {
                    let tempCurr: any = []
                    res.data.data.forEach((currency: any) => {
                        let tmp: any = {
                            optionName: currency.cur_name,
                            optionShortName: currency.cur_code,
                            optionFlag: currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }, [])

    function addBankAcct() {
        //validate dropdownlists
        if (!country) {
            setCountryErr(true)
            return
        }
        if (!currency) {
            setCurrencyErr(true)
            return
        }

        setLoading(true)
        let formData = {
            prtCode: entityId,
            prtCurrCode: currency,
            prtBankCtrCode: country,
            prtBankName: bank,
            bankCode: bankCode,
            prtBankBranch: branch,
            prtBankSwift: swift,
            prtBankAddress: address,
            prtBankAccName: acctName,
            prtBankAccNum: acctNo,
            prtMainAcc: mainAcct,
            action: 'savPrtAcc'
        }

        axiosSend({
            url: `/fx/admin/part-acc-v2`,
            method: 'POST',
            data: formData
        }).then(res => {
            setLoading(false)
            setResp(res.data)
            setStage(2)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e) => {
                    e.preventDefault()
                    addBankAcct()
                }}>
                    <div className="flex flex-col gap-[40px]">
                        <h4 className="font-bold text-[19px]">{t('common:liquidity_providers.add_bank_acct')}</h4>
                        <BankLists selectedBank={(bank)=>{
                            setBank(bank ? bank.bankName : "")
                            setBankCode(bank ? bank.bankCode: "")
                        }} selectedCountry={setCountry} />

                        <TextField label={t('common:general.branch')} value={branch} onChange={(e) => { setBranch(e.target.value) }} required={true} />
                        <TextField label={t('common:liquidity_providers.acct_number')} value={acctNo} onChange={(e) => {
                            if (isNaN(Number(e.target.value))) {
                                return
                            }
                            setAcctNo(e.target.value)
                        }} required={true} />
                        <TextField label={t('common:liquidity_providers.account_name')} value={acctName} onChange={(e) => { setAcctName(e.target.value) }} required={true} />
                        <div className="flex flex-col gap-[8px]">
                            <CountrySelector options={currencies} selectedOption={(e) => { setCurrency(e.optionShortName) }} placeholder={t('common:general.select_currency')} label={t('common:general.currency')} inputName="currency" />
                            {currencyErr && <p className="text-error-500"><i className="icon-exclamation-triangle text-error-500" /> {t('common:general.this_field_required')}</p>}
                        </div>
                        <TextField label={t('common:liquidity_providers.swift_code')} value={swift} onChange={(e) => { setSwift(e.target.value) }} required={true} />
                        <TextField label={t('common:provider_dets.address')} value={address} onChange={(e) => { setAddress(e.target.value) }} required={true} />
                        <div className="flex flex-col gap-[12px]">
                            <DropdownList options={mainAcctOpts} initialText={t('common:general.yes')} returnedSelection={(e) => { setMainAcct(e.value) }} label={t('common:liquidity_providers.main_account')} inputName="mainAccount" />
                        </div>
                        <Button type="submit" size="lg" variant="primary" disabled={loading}>{loading ? <LoadingSpinner variant={"primary"} /> : t('common:general.add_account')}</Button>
                    </div>
                </form>
            }
            {stage === 2 &&
                <>
                    {resp.Status === 'SUCC' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionSuccess headingText={t('common:brokers.bank_acct_added')} />
                            </div>
                            <Button size="lg" variant="primary" onClick={() => { window.location.reload() }}>{t('common:general.sounds_good')}</Button>
                        </div>
                    }
                    {resp.Status === 'FAIL' &&
                        <div className="flex flex-col flex-1">
                            <div className="flex flex-col flex-1 justify-center items-center">
                                <ActionFailed headingText={resp.Message} />
                            </div>
                            <Button size="lg" variant="primary" onClick={() => { setStage(1) }}>{t('common:general.okay')}</Button>
                        </div>
                    }
                </>
            }
        </>
    )
}

export default AddBankAccount
