import {useEffect, useState} from "react";
import HDivider from "../HDivider";
import {axiosSend} from "../../utils/request";
import {
    ActionFailed,
    ActionSuccess,
    Button,
    CountrySelector,
    DropdownList, EmailField, FileUpload,
    LoadingSpinner,
    TextField
} from "@secondstaxorg/sscomp";
import { ucFirstAll } from "../../helpers";
import {useTranslation} from "react-i18next";
import BankLists from "../BankLists";

interface AddIssuerProps {
    stepString:(a:number)=>void,
    stepTitle:(a:string)=>void,
    step:number,
    brokerType?: 'issuer' | 'intermediary'
}

const AddIssuer = (props:AddIssuerProps) => {
    const {t} = useTranslation('common')
    const {stepString,stepTitle,step,brokerType} = props
    const [stage,setStage] = useState(1)
    const [countries,setCountries]:any = useState([])
    const [currencies,setCurrencies]:any = useState([])
    const [industries,setIndustries]:any = useState([])
    const [brokerName1,setBrokerName1] = useState('')
    const [industry,setIndustry] = useState('')
    const [logo,setLogo] = useState<File>()
    const [tin,setTin] = useState('')
    const [brokerCountry,setBrokerCountry] = useState('')
    const [email,setEmail] = useState('')
    const BROKER_TYPE = brokerType && brokerType?.toUpperCase() === 'INTERMEDIARY' ? 'intermediary' :'issuer';
    const ORG_TYPE = brokerType && brokerType?.toUpperCase() === 'INTERMEDIARY' ? 'CROWDFUND' :'ISSUER'
    //contact person 1
    const [name1,setName1] = useState('')
    const [phone1,setPhone1] = useState('')
    const [email1,setEmail1] = useState('')

    //bank info
    const [bankName,setBankName] = useState('')
    const [bankCode,setBankCode] = useState('')
    const [bankCountry,setBankCountry] = useState('')
    const [bankBranch,setBankBranch] = useState('')
    const [acctNo,setAcctNo] = useState('')
    const [acctName,setAcctName] = useState('')
    const [acctCurrency,setAcctCurrency] = useState('')
    const [swift,setSwift] = useState('')
    const [banks,setBanks]:any = useState([])
    const mainAcctOpts = [
        {
            label:t('common:general.yes'),
            value:'Y'
        },
        {
            label:t('common:general.no'),
            value:'N'
        }
    ]
    const[processing,setProcessing] = useState(false)
    const [resp,setResp]:any = useState({})

    useEffect(()=>{
        if (stage === 1){
            stepTitle(t('common:liquidity_providers.basic_info'))
        } else if(stage === 2){
            stepTitle(t('common:liquidity_providers.bank_info'))
        }
        stepString(stage)
    },[stage])

    useEffect(()=>{
        if (step){
            setStage(step)
        }
    },[step])

    useEffect(()=>{
        axiosSend({
            url:'/fx/lov/currency',
            method:'GET'
        })
            .then(res => {
                if (res.data.Status === 'SUCC'){
                    let tempCurr:any = []
                    res.data.data.forEach((currency:any) => {
                        let tmp:any = {
                            optionName:currency.cur_name,
                            optionShortName:currency.cur_code,
                            optionFlag:currency.file_path
                        }
                        tempCurr.push(tmp)
                    })
                    setCurrencies(tempCurr)
                }
            })
            .catch(err => {
                console.log(err)
            })


            //get countries

        axiosSend({
            url:'/fx/lov/country',
            method:'GET'
        }).then(res => {
            if(res.data.Status === 'SUCC'){
                let cntries = res.data.data.sort((a:any,b:any)=>{
                    if (a.cty_name < b.cty_name) return -1
                })
                let tmp:any = []
                cntries.forEach((country:any) => {
                    if (country.cty_flag_name && country.cty_upd_rem){
                        tmp.push({
                            optionName:ucFirstAll(country.cty_name),
                            optionShortName:country.cty_code,
                            optionFlag:country.cty_flag_name
                        })
                    }
                })
                setCountries(tmp)
            }
        })

        //get banks
        axiosSend({
            url:'/fx/lov/banks',
            method:'GET'
        }).then(res => {
            if (res.data.Status === 'SUCC'){
                let bnks = res.data.data.sort((a:any,b:any)=>{
                    if (a.bank_name < b.bank_name) return -1
                })

                let tmp = bnks.map((bank:any) => {
                    return {
                        optionName:bank.bank_name,
                        optionShortName:bank.bank_code,
                        optionFlag:bank.logos[16],
                        swiftCode:bank.swift_code,
                        bankCountry:bank.country_code
                    }
                })
                setBanks(tmp)
            }
        }).catch(err => {
            console.log(err)
        })


        //get industries
        axiosSend({
            url:'/fx/lov/industry',
            method:'GET'
        }).then(res => {
            if (res.data.data && res.data.data.length > 0){
                const items = res.data.data.map((industry:any) => {
                    return {
                        label:industry.industry_name,
                        value:industry.industry_code
                    }
                })
                setIndustries(items)
            }
        }).catch(console.log)
    },[])

    function validateStep1(){
        if (!brokerCountry || !brokerName1 || !email) return null
        setStage(2)
    }

    function validateStep2(){
        if (!bankCountry || !bankName || !bankBranch || !acctNo || !acctCurrency || !swift) return null
        addBroker()
    }

    async function addBroker(){
        let logoUrl:any

        if (!logo){
            setResp({
                Status:'FAIL',
                Message:'No logo selected'
            })
            setStage(stage + 1)
            return
        }

        setProcessing(true)

        const fileForm = new FormData()
        fileForm.append('file',logo)

        logoUrl = await axiosSend({
            url:'/upload',
            method:'POST',
            data:fileForm
        })


        const formData = {
            orgName1:brokerName1,
            orgCity:brokerCountry, //this line is rather country
            orgTin:tin,
            orgEmail:email,
            orgContact1Name:name1,
            orgContact1Phone:phone1,
            orgContact1Email:email1,
            prtCurrCode:acctCurrency,
            prtBankCtrCode:bankCountry,
            prtBankName:bankName,
            bankCode:bankCode,
            prtBankBranch:bankBranch,
            prtBankSwift:swift,
            prtBankAccName:acctName,
            prtBankAccNum:acctNo,
            orgLogo: logoUrl.data.url,
            industryCode:industry,
            orgType: ORG_TYPE,
            action:'savOrg'
        }

        axiosSend({
            url:'/fx/admin/org',
            method:'POST',
            data:formData
        }).then(res => {
            setResp(res.data)
            setProcessing(false)
            setStage(4)
        }).catch(err => {
            console.log(err)
            setProcessing(false)
        })
    }

    return (
        <>
            {stage === 1 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    validateStep1()
                }}>
                    <div className="flex flex-col gap-[24px]">
                        <TextField label={`${t(`common:${BROKER_TYPE}.${BROKER_TYPE}_name`)} #1`} required={true} value={brokerName1}
                                   onChange={(e) => {
                                       setBrokerName1(e.target.value)
                                   }} autoFocus
                                   labelOverrides={{errMsg: t('common:general.this_field_required')}}/>
                        <DropdownList label={t('common:brokers.industry')} initialText={industry} options={industries}
                                      returnedSelection={(a) => {
                                          setIndustry(a.value)
                                      }} inputName="" required labelOverrides={{
                            requiredField: t('common:general.this_field_required'),
                            search: t('common:general.search')
                        }}/>
                        <FileUpload onChange={setLogo} label="Logo" required accept=".jpg,.png" consolidateButton/>
                        <TextField label={t('common:brokers.tin')} value={tin} onChange={(e) => {
                            setTin(e.target.value)
                        }}/>
                        <CountrySelector options={countries} selectedOption={(e) => {
                            setBrokerCountry(e.optionShortName)
                        }} placeholder={t('common:general.select_country')} label={t('common:general.country')}
                                         required={true} inputName="country" initialCountry={brokerCountry}/>
                        <EmailField label={t('common:login.email')} value={email} required={true} onChange={(e) => {
                            setEmail(e.target.value)
                        }} labelOverrides={{
                            requiredField: t('common:general.this_field_required'),
                            enterValidEmail: t('common:general.enter_valid_email')
                        }}/>

                        {/*contact person*/}
                            <h6 className="font-bold">{`${t('common:brokers.contact_person')}`}</h6>
                            <HDivider twBorderColor="border-neutral-900"/>
                            <TextField label={t('common:general.name')} required={true} value={name1} onChange={(e) => {
                                setName1(e.target.value)
                            }} labelOverrides={{errMsg: t('common:general.this_field_required')}}/>
                            <TextField label={t('common:general.phone_number')} required={true} value={phone1}
                                       onChange={(e) => {
                                           if (isNaN(Number(e.target.value))) {
                                               return
                                           }
                                           setPhone1(e.target.value)
                                       }} labelOverrides={{errMsg: t('common:general.this_field_required')}}/>
                            <EmailField label={t('common:login.email')} required={true} value={email1}
                                        onChange={(e) => {
                                            setEmail1(e.target.value)
                                        }} labelOverrides={{
                                requiredField: t('common:general.this_field_required'),
                                enterValidEmail: t('common:general.enter_valid_email')
                            }}/>
                            <Button size="lg" variant="primary"
                                    type="submit">{t('common:general.confirm_proceed')}</Button>
                        </div>
                </form>
            }
            {stage === 2 &&
                <form onSubmit={(e)=>{
                    e.preventDefault()
                    validateStep2()
                }}>
                    <div className="flex flex-col gap-[40px]">
                        {/* <CountrySelector options={countries} selectedOption={(e)=>{setBankCountry(e.optionShortName)}} placeholder={t('common:general.select_country')} label={t('common:general.country')} required={true} inputName="country" labelOverrides={{search:t('common:general.search'),requiredField:t('common:general.this_field_required')}}/>
                        <CountrySelector options={banks} selectedOption={(a)=>{
                            setBankName(a.optionName)
                            setBankCode(a.optionShortName)
                            a.swiftCode ? setSwift(a.swiftCode) : setSwift('')
                        }} label={t('common:liquidity_providers.bank_name')} placeholder={t('common:liquidity_providers.select_bank')} searchable={true} required={true} inputName="bank" labelOverrides={{search:t('common:general.search'),requiredField:t('common:general.this_field_required')}}/> */}
                        
                        <BankLists selectedBank={(bank)=>{
                            if (bank){
                            setBankName(bank.bankName)
                            setBankCode(bank.bankCode)
                            bank.swiftCode ? setSwift(bank.swiftCode) : setSwift('')
                        } else {
                            setBankName('')
                            setBankCode('')
                            setSwift('')
                        }
                        }} selectedCountry={(country)=>{
                            setBankCountry(country)
                        }}/>

                        <TextField label={t('common:general.branch')} placeholder={t('common:general.enter_branch_name')} required={true} value={bankBranch} onChange={(e)=>{setBankBranch(e.target.value)}}/>
                        <TextField label={t('common:liquidity_providers.acct_number')} placeholder={t('common:liquidity_providers.enter_acct_number')} required={true} value={acctNo} onChange={(e)=>{
                            if (isNaN(Number(e.target.value))) return
                            setAcctNo(e.target.value)
                        }} labelOverrides={{errMsg:t('common:general.this_field_required')}}/>
                        <TextField label={t('common:general.account_name')} placeholder={t('common:brokers.enter_acct_name')} required={true} value={acctName} onChange={(e)=>{setAcctName(e.target.value)}} labelOverrides={{errMsg:t('common:general.this_field_required')}}/>
                        <div className="flex flex-col gap-[12px]">
                            <CountrySelector options={currencies} selectedOption={(e)=>{setAcctCurrency(e.optionShortName)}} placeholder={t('common:general.select_currency')} label={t('common:general.currency')} required={true} inputName="currency" labelOverrides={{search:t('common:general.search'),requiredField:t('common:general.this_field_required')}}/>
                        </div>
                        <TextField label={t('common:liquidity_providers.swift_code')} placeholder={t('common:liquidity_providers.enter_swift')} required={true} value={swift} onChange={(e)=>{setSwift(e.target.value)}} labelOverrides={{errMsg:t('common:general.this_field_required')}}/>
                        <Button size="lg" variant="primary" disabled={processing} type="submit">{processing ? <LoadingSpinner variant={"primary"}/> : t('common:brokers.confirm_add_broker')}</Button>
                    </div>
                </form>
            }
            {stage === 4 &&
                <div className="flex flex-col flex-1">
                    <div className="flex flex-col flex-1 justify-center items-center">
                        {resp.Status === 'SUCC' &&
                            <ActionSuccess headingText={t('common:brokers.broker_added')} subText={t('common:brokers.broker_added_msg',{brokerName:`${brokerName1}`})}/>
                        }
                        {resp.Status === 'FAIL' &&
                            <ActionFailed headingText={resp.Message}/>
                        }
                    </div>
                    <Button size="lg" variant="primary" onClick={()=>{
                        if (resp.Status === 'SUCC') window.location.reload()
                        if (resp.Status === 'FAIL') setStage(stage - 1)
                    }}>{resp.Status === 'SUCC' ? t('common:general.sounds_good') : t('common:general.try_again')}</Button>
                </div>
            }
        </>
    )
}

export default AddIssuer
