import {iDashboardBaseLayout} from "./type";
import {Link, useNavigate} from "react-router-dom";
import React, { useContext, useEffect, useRef, useState} from "react";
import BugReportingForm from "../BugReportingForm";
import {motion,AnimatePresence} from "framer-motion"
import {user as userType} from "../../utils/type";
import UserContext from "../../utils/userContext";
import { axiosSend } from "../../utils/request";
import {cmPageNames, getCurrFlag, moneyfy} from "../../helpers";
import SidePanel from "../SidePanel/SidePanel";
import BackButton from "../BackButton";
import ProfileContent from "../ProfileContent/ProfileContent";
import dayjs from "dayjs";
import {useIdleTimer} from "react-idle-timer";
import {LanguageSwitcher, LoadingSpinner, PopupModal, Ribbon, TablePrimary, Tooltip} from "@secondstaxorg/sscomp"
import ExportPreviewModal from "../ExportPreviewModal/ExportPreviewModal";
import typography from "@secondstaxorg/sscomp/dist/typography.module.css";
import {useTranslation} from "react-i18next";
import 'dayjs/locale/fr'

const relativeTime = require('dayjs/plugin/relativeTime');
dayjs.extend(relativeTime)

const DashboardBaseLayout = (props:iDashboardBaseLayout) => {
    const {children,pageName,topLevelButtons} = props
    const [t,i18n] = useTranslation('common')
    const [profileCard,setProfileCard] = useState(false)
    const [reportBugCard,setReportBugCard] = useState(false)
    const [notifCard,setNotifCard] = useState(false)
    const userData:any = useContext(UserContext)
    const [notificationsAvailable,setNotificationsAvailable] = useState(false)
    const [notifications,setNotifications]:any = useState([])
    const [expandCM,setExpandCM] = useState(false)
    const notifRef:any = useRef(null)
    const profileCardRef:any = useRef(null)
    const [profilePnl,setProfilePnl] = useState(false)
    const [userDets,setUserDets]:any = useState({})
    const [profilePnlTitle,setProfilePnlTitle] = useState(t('common:dashboard.user_profile'))
    const [pwStep,setPwStep] = useState(1)
    const [userInits,setUserInits] = useState('')
    const navigate = useNavigate()
    const [inactivePrompt,setInactivePrompt] = useState(false)
    const [navLinks,setNavLinks]:any = useState([])
    const [logoutPrompt,setLogoutPrompt] = useState(false)
    const [ratesModal,setRatesModal] = useState(false)
    const [loadingProvRates,setLoadingProvRates] = useState(false)
    const [provRates,setProvRates] = useState([])
    const [menuCollapsed,setMenuCollapsed] = useState(false)
    const [view,setView] = useState('profile')

    const idleFxn = async () => {
        localStorage.setItem('logoutReason','inactivity')
        await logoutUser()
    }

    const resetTimer = () => {
        idleTimer.reset()
    }

    const idleTimer = useIdleTimer({
        promptBeforeIdle:180000, //prompt 3 mins before timeout
        onPrompt:()=>{
            setInactivePrompt(true)
        },
        timeout:600000, //idle for 10 mins total
        onIdle:idleFxn,
        stopOnIdle:true
    })

    useEffect(()=>{
        setNavLinks([
            {
                label: t('pill_menu.equity'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/trade`
            },
            {
                label: t('pill_menu.fixed_income'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/trade`
            },
            {
                label: t('pill_menu.my_accounts'),
                url: `${process.env.REACT_APP_TRADING_PORTAL_URL}/accounts`
            },
            {
                label: t('pill_menu.newsroom'),
                url: process.env.REACT_APP_NEWS_APP_URL
            },
            {
                label: t('pill_menu.research'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/research`
            },
            {
                label: t('pill_menu.mkt_data'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/market-data`
            },
            {
                label: t('pill_menu.analytics'),
                url: `${process.env.REACT_APP_NEWS_APP_URL}/analytics`
            },
            {
                label: t('pill_menu.liquidity'),
                url: process.env.REACT_APP_URL
            }
        ])
    },[t])

    useEffect(()=>{
        if (userData && Object.keys(userData).length > 0){
            setUserDets(userData.profile[0])
            //generate user initials for the profile dropdown
            let ini = ''
            let spl = userData.profile[0].user_name.split(' ')
            ini += spl[0][0]+spl[spl.length-1][0]
            setUserInits(ini)
        }

        //wait till the dashboard is rendered, then perform these actions
        if(userData && userData.profile){
            //load user's preference for sidebar collapse status
            const sidebarStatus = localStorage.getItem('sidebarCollapsePref')
            //check if a value was retrieved for the sidebar status
            if (sidebarStatus){
                const sideBar = (document as any).querySelector('.sidebar-nav').classList
                if(sidebarStatus === 'true'){ //false if user chose to have it collapsed
                    sideBar.add('sidebar-collapse') //collapse they already expanded sidebar
                }
            }
        }
    },[userData])

    useEffect(()=>{
        axiosSend({
            url: `/fx/notification/notifications`,
            method: 'GET'
        }).then(res  => {
            if (res.data.data){
                setNotifications(res.data.data)
                //check if there are any unread notifications
                for(let i = 0; i < res.data.data.length; i++){
                    if (res.data.data[i].read === 'N'){
                        setNotificationsAvailable(true)
                        break
                    }
                }
            }
        }).catch(err => {
            console.log(err)
        })
    },[])

    useEffect(()=>{
        setMenuCollapsed(localStorage.getItem('sidebarCollapsePref') === 'true')
    },[])

    useEffect(()=>{
        function handler(e:MouseEvent){
            if(notifRef.current){
                if (!notifRef.current.contains(e.target)) {
                    setTimeout(()=>{
                        setNotifCard(false)
                    },10)
                }
            }

            if(profileCardRef.current){
                if (!profileCardRef.current.contains(e.target)) {
                    setTimeout(()=>{
                        setProfileCard(false)
                    },10)
                }
            }
        }

        window.addEventListener('mouseup',handler)
        return ()=>window.removeEventListener('mouseup',handler)
    },[])

    function logoutUser(){
        if(localStorage.getItem('fx_data')){
            localStorage.removeItem('fx_data')
        }
        axiosSend({
            url: "/logout",
            method: "POST",
        }).then(res => {
            if(res.data.Status === "OK"){
                navigate("/")
            }
        }).catch(()=>{
            navigate('/')
        })
    }

    function readNotif(txnId:string,notifId:string){
        axiosSend({
            url:`/fx/notification/notifications/${notifId}`,
            method:'PUT'
        }).then(res => {
            if (res.data.data){
                setNotifications(res.data.data)
                //check if there are any unread notifications, then set notificationsAvailable to true
                for(let i = 0; i < res.data.data.length; i++){
                    if (res.data.data[i].read === 'N'){
                        setNotificationsAvailable(true)
                        break
                    }
                }
            }

            //save the transactionID to localstorage and then navigate to the trading history page
            //use that to load up the transaction details on the trading history page
            localStorage.setItem('txnId',txnId)
            dispatchEvent(new Event('storage'))
            navigate('/transaction-history')
            setNotifCard(false)
        })
    }

    function getProvRates(){
        setLoadingProvRates(true)
        setRatesModal(true)
        axiosSend({
            url:'/fx/admin/liq-prov-valid-rates',
            method:'GET'
        }).then(res => {
            if(res.data.Status && res.data.Status === 'SUCC' && res.data.data){
                setProvRates(res.data.data)
            }
            setLoadingProvRates(false)
        }).catch(err => {
            console.log(err)
        })
    }

    return (
        <>
            <PopupModal title={t('common:dashboard.user_inactivity')} content={t('common:dashboard.user_inactivity_msg')} primaryAction={()=>{
                resetTimer()
                setInactivePrompt(false)
            }} primaryBtnText={t('common:dashboard.im_here')} closeFxn={()=>{
                setInactivePrompt(false)
                resetTimer()}} secondaryAction={logoutUser} secondaryBtnText={t('common:dashboard.log_me_out')} showModal={inactivePrompt}/>
            <PopupModal title={t('common:dashboard.confirm_sign_out')} content={t('common:dashboard.confirm_sign_out_msg')} primaryAction={logoutUser} primaryBtnText={t('common:general.yes')} closeFxn={()=>{setLogoutPrompt(false)}} secondaryBtnText={t('common:general.cancel')} secondaryAction={()=>{setLogoutPrompt(false)}} showModal={logoutPrompt}/>

            {userData && Object.keys(userData).length === 0 &&
                <>
                    <div className="w-screen h-screen flex justify-center items-center">
                        <div className="flex items-center flex-col w-[560px]">
                            <img src="https://res.cloudinary.com/dxivfczqg/image/upload/v1680257873/dashboard-logo-no-name-dark_vrkbad.svg"
                                 alt="SSX Logo" className="mb-[40px] w-[57px]"/>
                            <div className="w-[333px] flex flex-col gap-[8px] items-center mb-[40px]">
                                <h5 className="font-[700] text-[23px] mb-[8px]">{t('common:general.loading')}...</h5>
                                <p className="text-center">{t('common:splash.message')}</p>
                            </div>
                            <div className="h-[6px] bg-neutral-200 rounded-full w-full relative overflow-hidden loading-bar">
                                <div className="bg-primary-500 rounded-full absolute h-full w-[140px]"/>
                            </div>
                        </div>
                    </div>
                </>
            }
            {userData && userData.profile &&
                <>
                    <div className="top-nav">
                        <div className="py-[8px] px-[32px] flex justify-between items-center gap-[16px]">
                            <Ribbon links={navLinks} bgColor="light" currentPageItem="Liquidity"/>
                        </div>
                        <div className="flex gap-[24px] items-center px-[32px] relative">
                            <div className="flex gap-[16px] items-center">
                                {topLevelButtons && topLevelButtons}
                                <LanguageSwitcher languages={[{language:t('common:general.english'),shortName:'en',flag:`/assets/images/flags/uk.svg`},{language:t('common:general.french'),shortName:'fr',flag:`/assets/images/flags/france.svg`}]} onClick={(a)=>{
                                    i18n.changeLanguage(a.shortName)
                                        .then(()=>{
                                            localStorage.setItem('userLang',a.shortName)
                                            document.documentElement.setAttribute('lang',a.shortName)
                                        })
                                }} initialLanguage={i18n.language}/>
                                {userData && (userData as userType).profile && (userData as userType).profile.length > 0 &&
                                    <>
                                        {userData.profile[0].user_type === 'SX' &&
                                            <>
                                                {/*provider rates button*/}
                                                <button className={`p-[8px] w-[40px] h-[40px] hover:bg-neutral-50 active:bg-neutral-100 rounded-[8px] ${ratesModal ? 'bg-neutral-100' : ''}`} onClick={getProvRates}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_219_53985)">
                                                            <path d="M21.7101 9.28994L17.7101 5.28994C17.6169 5.1967 17.5062 5.12274 17.3844 5.07228C17.2626 5.02182 17.132 4.99585 17.0001 4.99585C16.7338 4.99585 16.4784 5.10164 16.2901 5.28994C16.1018 5.47825 15.996 5.73364 15.996 5.99994C15.996 6.26624 16.1018 6.52164 16.2901 6.70994L18.5901 8.99994H7.00014C6.73492 8.99994 6.48057 9.1053 6.29303 9.29283C6.1055 9.48037 6.00014 9.73472 6.00014 9.99994C6.00014 10.2652 6.1055 10.5195 6.29303 10.707C6.48057 10.8946 6.73492 10.9999 7.00014 10.9999H21.0001C21.1976 10.999 21.3903 10.9396 21.554 10.8292C21.7177 10.7189 21.8451 10.5626 21.9201 10.3799C21.9967 10.1978 22.0176 9.99712 21.9803 9.80313C21.9429 9.60914 21.8489 9.43057 21.7101 9.28994ZM17.0001 12.9999H3.00014C2.80271 13.0009 2.61 13.0603 2.44628 13.1707C2.28256 13.281 2.15516 13.4373 2.08014 13.6199C2.00356 13.8021 1.98264 14.0028 2.02001 14.1968C2.05739 14.3907 2.15138 14.5693 2.29014 14.7099L6.29014 18.7099C6.3831 18.8037 6.4937 18.8781 6.61556 18.9288C6.73742 18.9796 6.86813 19.0057 7.00014 19.0057C7.13215 19.0057 7.26286 18.9796 7.38472 18.9288C7.50658 18.8781 7.61718 18.8037 7.71014 18.7099C7.80387 18.617 7.87826 18.5064 7.92903 18.3845C7.9798 18.2627 8.00594 18.132 8.00594 17.9999C8.00594 17.8679 7.9798 17.7372 7.92903 17.6154C7.87826 17.4935 7.80387 17.3829 7.71014 17.2899L5.41014 14.9999H17.0001C17.2654 14.9999 17.5197 14.8946 17.7072 14.707C17.8948 14.5195 18.0001 14.2652 18.0001 13.9999C18.0001 13.7347 17.8948 13.4804 17.7072 13.2928C17.5197 13.1053 17.2654 12.9999 17.0001 12.9999Z" fill="#070B12"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_219_53985">
                                                                <rect width="24" height="24" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </button>
                                            </>
                                        }
                                    </>
                                }
                                {/*BUG REPORTING BUTTON*/}
                                <div className="relative">
                                    <button className={`p-[8px] w-[40px] h-[40px] hover:bg-neutral-50 active:bg-neutral-100 rounded-[8px] ${reportBugCard ? 'bg-neutral-100' : ''}`} onClick={()=>{
                                        setReportBugCard(!reportBugCard)
                                    }}>
                                        <i className="icon-bug text-[20px]"/>
                                    </button>
                                    {reportBugCard &&
                                        <BugReportingForm clsFxn={(a)=>{setReportBugCard(a)}} loggedInUser={userData.profile[0]}/>
                                    }
                                </div>

                                {/*NOTIFICATIONS BUTTON*/}
                                <div className="relative">
                                    <button className={`p-[8px] w-[40px] h-[40px] relative flex items-center justify-center hover:bg-neutral-50 active:bg-neutral-100 rounded-[8px] ${notifCard ? 'bg-neutral-100' : ''}`} onClick={()=>{
                                        setNotifCard(!notifCard)
                                    }}>
                                        <i className="icon-notification text-[20px]"/>
                                        {notificationsAvailable &&
                                            <div className="notification-dot"/>
                                        }
                                    </button>
                                    <AnimatePresence>
                                        {notifCard &&
                                            <motion.div ref={notifRef} className="top-nav-card w-[430px]"
                                                        animate={{
                                                            y:0,
                                                            opacity:1
                                                        }}
                                                        initial={{
                                                            y:-10,
                                                            opacity:0
                                                        }}
                                                        exit={{
                                                            y:-10,
                                                            opacity:0
                                                        }}
                                                        transition={{
                                                            duration:0.2,
                                                            ease:"easeOut"
                                                        }}
                                            >
                                                {notifications.length > 0 &&
                                                    <>
                                                        <div className="py-[12px] px-[16px]">
                                                            <p className="paragraph-md-regular">{t('common:dashboard.notifications')}</p>
                                                        </div>
                                                        <div className="notif-list">
                                                            {notifications.map((notification:any,index:number) => {
                                                                if (index < 10)
                                                                    return (
                                                                        <div key={index}
                                                                             className={notification.read === 'N' ? 'unread-notif' : 'read-notif'}
                                                                             onClick={() => {
                                                                                 readNotif(notification.transaction_num, notification.notification_id)
                                                                             }}>
                                                                            {notification.LP_logos
                                                                                ?
                                                                                <img src={notification.LP_logos[16]}
                                                                                     className="w-[24px] h-[24px] rounded-[4px] object-contain"
                                                                                     alt="provider logo"/>
                                                                                :
                                                                                <div
                                                                                    className="w-[24px] h-[24px] rounded-[4px] bg-neutral-100"/>
                                                                            }
                                                                            <div className="flex w-full">
                                                                                <div
                                                                                    className="flex flex-col gap-[12px] w-[87%]">
                                                                                    <div>
                                                                                        <p className="paragraph-md-regular text-neutral-900">{notification.title}</p>
                                                                                        <p className="text-[12px] text-neutral-500">{notification.provider_name} •
                                                                                            #{notification.transaction_num}</p>
                                                                                    </div>
                                                                                    <div className="txn-amts">
                                                                                        <div
                                                                                            className="flex gap-[16px] items-center justify-between">
                                                                                            <div className="flex gap-[8px]">
                                                                                                <img
                                                                                                    src={notification.base_cur_flag}
                                                                                                    alt={notification.base_currency}/>
                                                                                                <p className="text-neutral-900 text-[14px]">{moneyfy(notification.base_amount, notification.base_currency)}</p>
                                                                                            </div>
                                                                                            <div>
                                                                                                <i className="icon-arrows-left-right"/>
                                                                                            </div>
                                                                                            <div className="flex gap-[8px]">
                                                                                                <img
                                                                                                    src={notification.exch_cur_flag}
                                                                                                    alt={notification.exch_currency}/>
                                                                                                <p className="text-neutral-900 text-[14px]">{moneyfy(notification.exch_amount, notification.exch_currency)}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div
                                                                                    className="text-neutral-500 text-[12px] text-right w-full">{dayjs(notification.created).locale(i18n.language).format('D MMM')}</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                            })}
                                                        </div>
                                                    </>
                                                }
                                                {notifications.length === 0 &&
                                                    <div className="h-[477px] flex justify-center items-center">
                                                        <div className="flex flex-col gap-[16px] items-center">
                                                            <svg width="64" height="65" viewBox="0 0 64 65" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_499_2669)">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M46 26.5003C46 26.5003 46 28.3139 46 24.5001C46 16.768 39.732 10.501 32 10.501C24.268 10.501 18 16.768 18 24.5001C18 28.3139 18 26.5003 18 26.5003" fill="#E8EEF7"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M15.521 48.5003H37C38.104 48.5003 39 49.397 39 50.5006C39 51.6041 38.104 52.5008 37 52.5008H12C11.253 52.5008 10.567 52.0834 10.224 51.4198C9.87998 50.7562 9.93499 49.9559 10.367 49.3459C10.367 49.3459 16 41.4092 16 32.501V24.5001C16 15.6644 23.163 8.50076 32 8.50076C40.837 8.50076 48 15.6644 48 24.5001C48 28.3139 48 28.4982 48 32.501C48 41.4092 53.633 49.3459 53.633 49.3459C54.065 49.9559 54.12 50.7562 53.776 51.4198C53.433 52.0834 52.747 52.5008 52 52.5008H46C44.896 52.5008 44 51.6041 44 50.5006C44 49.397 44.896 48.5003 46 48.5003H48.479C46.601 44.997 44 38.9726 44 32.501C44 28.4982 44 28.3139 44 24.5001C44 17.8728 38.627 12.5 32 12.5C25.373 12.5 20 17.8728 20 24.5001V32.501C20 38.9726 17.399 44.997 15.521 48.5003Z" fill="#D2DDEE"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M37.658 50.5C37.88 51.126 38 51.799 38 52.5C38 55.811 35.311 58.5 32 58.5C28.689 58.5 26 55.811 26 52.5C26 51.799 26.12 51.126 26.342 50.5H37.658Z" fill="#E8EEF7"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M39.543 49.833C39.261 49.034 38.505 48.5 37.658 48.5H26.342C25.495 48.5 24.739 49.034 24.457 49.833C24.161 50.667 24 51.565 24 52.5C24 56.915 27.585 60.5 32 60.5C36.415 60.5 40 56.915 40 52.5C40 51.565 39.839 50.667 39.543 49.833ZM28.005 52.706C28.113 54.818 29.861 56.5 32 56.5C34.208 56.5 36 54.708 36 52.5H28L28.005 52.706ZM30 6.5V10.5C30 11.604 30.896 12.5 32 12.5C33.104 12.5 34 11.604 34 10.5V6.5C34 5.396 33.104 4.5 32 4.5C30.896 4.5 30 5.396 30 6.5Z" fill="#D2DDEE"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_499_2669">
                                                                        <rect width="64" height="64" fill="white" transform="translate(0 0.5)"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <div className="text-center">
                                                                <p className="paragraph-md-regular mb-[8px] text-neutral-900">No Notifications</p>
                                                                <p className="text-[14px] text-neutral-900">Your notifications will show up here when you have them</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </motion.div>
                                        }
                                    </AnimatePresence>
                                </div>
                            </div>

                            {/*PROFILE CARD*/}
                            <div className="relative">
                                <button className="flex items-center profile-button" onClick={()=>{
                                    setProfileCard(!profileCard)
                                }}>
                                    <div className="w-[40px] h-[40px] bg-primary-100 rounded-full mr-1 flex justify-center items-center font-bold text-primary-500">
                                        {userInits}
                                    </div>
                                    <div className="w-[16px] h-[16px] flex justify-center items-center">
                                        <i className={`${profileCard ? '-rotate-180' : 'rotate-0'} icon-angle-down text-[8px] transition duration-300 ease-out`}/>
                                    </div>
                                </button>
                                <AnimatePresence>
                                    {profileCard &&
                                        <motion.div ref={profileCardRef} className="top-nav-card w-[290px]"
                                                    animate={{
                                                        translateY:0,
                                                        opacity:1
                                                    }}
                                                    initial={{
                                                        translateY:-10,
                                                        opacity:0
                                                    }}
                                                    exit={{
                                                        translateY:-10,
                                                        opacity:0
                                                    }}
                                                    transition={{
                                                        duration:0.2,
                                                        ease:"easeOut"
                                                    }}
                                        >
                                            <div className="py-[12px] px-[16px] paragraph-md-regular">
                                                {userData.profile[0].user_name}
                                            </div>
                                            <button className="ami text-left w-full" onClick={()=>{
                                                setProfilePnl(true)
                                                setProfileCard(false)
                                            }}>
                                                <i className="icon-user"/>
                                                <p className="w-[194px]">{t('dashboard.my_profile')}</p>
                                                <i className="icon-angle-right text-[10px]"/>
                                            </button>
                                            <button className="ami text-left w-full" onClick={()=>{setLogoutPrompt(true)}}>
                                                <i className="icon-logout"/>
                                                <p className="w-[194px]">{t('common:dashboard.sign_out')}</p>
                                                <i className="icon-angle-right text-[10px]"/>
                                            </button>
                                        </motion.div>
                                    }
                                </AnimatePresence>
                            </div>
                        </div>
                    </div>

                    {/*SIDE MENU*/}
                    <div className="flex">
                        <div className="sidebar-nav">
                            {menuCollapsed ?
                                <Tooltip tooltipPosition="right" tooltipContent={t('common:dashboard_side_menu.dashboard')} childElementClassName="dashboardMenu">
                                    <Link to="/dashboard" className={`sidebar-menu dashboardMenu ${pageName === 'dashboard' ? 'active-item' : ''}`}>
                                        <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                            <i className="text-[20px] icon-dashboard"/>
                                        </div>
                                        <div className="flex justify-between items-center w-full menu-label">
                                            <span className="side-menu-label">{t('common:dashboard_side_menu.dashboard')}</span>
                                            <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                        </div>
                                    </Link>
                                </Tooltip>
                                :
                                <Link to="/dashboard" className={`sidebar-menu ${pageName === 'dashboard' ? 'active-item' : ''}`}>
                                    <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                        <i className="text-[20px] icon-dashboard"/>
                                    </div>
                                    <div className="flex justify-between items-center w-full menu-label">
                                        <span className="side-menu-label">{t('common:dashboard_side_menu.dashboard')}</span>
                                        <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                    </div>
                                </Link>
                            }
                            {userData.apps.map((app:any,index:number) =>{
                                if(!app.APP_FX)return null
                                return (
                                    <React.Fragment key={index}>
                                        {menuCollapsed ?
                                            <Tooltip tooltipPosition="right" tooltipContent={t('common:dashboard_side_menu.request_lp')} childElementClassName="lpReq">
                                                <Link to="/request-lp" className={`sidebar-menu lpReq ${pageName === 'trade' ? 'active-item' : ''}`}>
                                                    <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                                        <i className="text-[20px] icon-arrows-left-right"/>
                                                    </div>
                                                    <div className="flex justify-between items-center w-full menu-label">
                                                        <span className="side-menu-label">{t('common:dashboard_side_menu.request_lp')}</span>
                                                        <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                                    </div>
                                                </Link>
                                            </Tooltip>
                                            :
                                            <Link to="/request-lp" className={`sidebar-menu ${pageName === 'trade' ? 'active-item' : ''}`}>
                                                <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                                    <i className="text-[20px] icon-arrows-left-right"/>
                                                </div>
                                                <div className="flex justify-between items-center w-full menu-label">
                                                    <span className="side-menu-label">{t('common:dashboard_side_menu.request_lp')}</span>
                                                    <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                                </div>
                                            </Link>
                                        }
                                    </React.Fragment>
                                )
                            })}

                            {userData.apps.map((app:any,index:number) =>{
                                if(!app.APP_REP)return null
                                return (
                                    <React.Fragment key={index}>
                                        {menuCollapsed ?
                                            <Tooltip tooltipPosition="right" tooltipContent={t('common:dashboard_side_menu.reports')} childElementClassName="reportMn">
                                                <Link to="/reports" className={`sidebar-menu reportMn ${pageName === 'report' ? 'active-item' : ''}`}>
                                                    <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                                        <i className="text-[20px] icon-report"/>
                                                    </div>
                                                    <div className="flex justify-between items-center w-full menu-label">
                                                        <span className="side-menu-label">{t('common:dashboard_side_menu.reports')}</span>
                                                        <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                                    </div>
                                                </Link>
                                            </Tooltip>
                                            :
                                            <Link to="/reports" className={`sidebar-menu ${pageName === 'report' ? 'active-item' : ''}`}>
                                                <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                                    <i className="text-[20px] icon-report"/>
                                                </div>
                                                <div className="flex justify-between items-center w-full menu-label">
                                                    <span className="side-menu-label">{t('common:dashboard_side_menu.reports')}</span>
                                                    <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                                </div>
                                            </Link>
                                        }
                                    </React.Fragment>
                                )
                            })}

                            {menuCollapsed ?
                                <Tooltip tooltipPosition="right" tooltipContent={t('common:dashboard_side_menu.transaction_history')} childElementClassName="thistory">
                                    <Link to="/transaction-history" className={`sidebar-menu thistory ${pageName === 'trading-history' ? 'active-item' : ''}`}>
                                        <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                            <i className="text-[20px] icon-history"/>
                                        </div>
                                        <div className="flex justify-between items-center w-full menu-label">
                                            <span className="side-menu-label">{t('common:dashboard_side_menu.transaction_history')}</span>
                                            <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                        </div>
                                    </Link>
                                </Tooltip>
                                :
                                <Link to="/transaction-history" className={`sidebar-menu ${pageName === 'trading-history' ? 'active-item' : ''}`}>
                                    <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                        <i className="text-[20px] icon-history"/>
                                    </div>
                                    <div className="flex justify-between items-center w-full menu-label">
                                        <span className="side-menu-label">{t('common:dashboard_side_menu.transaction_history')}</span>
                                        <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"></path></svg>
                                    </div>
                                </Link>
                            }

                            {/*CODE MAINTENANCE*/}
                            {userData && userData.apps.length > 0 &&
                                <div className={`${cmPageNames.includes(pageName) ? 'active-cm' : ''}`}
                                     onMouseEnter={()=>{
                                         if((document as any).querySelector('.sidebar-nav').classList.contains('sidebar-collapse')){
                                             setExpandCM(true)
                                         }
                                     }}
                                     onMouseLeave={()=>{
                                         if((document as any).querySelector('.sidebar-nav').classList.contains('sidebar-collapse')){
                                             setExpandCM(false)
                                         }
                                     }}>
                                    {userData.apps.map((app:any,index:number) => {
                                        if(!app.APP_CAM) return null
                                        return (
                                            <React.Fragment key={index}>
                                                <Link to="#" className="sidebar-menu"
                                                      onClick={()=>{
                                                          setExpandCM(!expandCM)}
                                                      }
                                                >
                                                    <div className="w-[24px] h-[24px] grid place-content-center menu-icon">
                                                        <i className={`icon-settings text-[20px] ${cmPageNames.includes(pageName) ? 'text-primary-500' : ''}`}/>
                                                    </div>
                                                    <div className={`flex justify-between items-center w-full menu-label ${cmPageNames.includes(pageName) ? 'text-primary-500' : ''}`}>
                                                        <span className="side-menu-label">{t('common:dashboard_side_menu.code_maintenance')}</span>
                                                        {/*ANGLE-RIGHT*/}
                                                        <svg className="menu-label-caret" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M5.35917 4.52637L1.58584 0.759699C1.52387 0.697213 1.45013 0.647617 1.36889 0.613771C1.28765 0.579926 1.20052 0.5625 1.11251 0.5625C1.0245 0.5625 0.937362 0.579926 0.856123 0.613771C0.774883 0.647617 0.701149 0.697213 0.639174 0.759699C0.515007 0.884607 0.445313 1.05357 0.445312 1.2297C0.445313 1.40582 0.515007 1.57479 0.639174 1.6997L3.93917 5.03303L0.639174 8.33303C0.515007 8.45794 0.445312 8.62691 0.445312 8.80303C0.445312 8.97915 0.515007 9.14812 0.639174 9.27303C0.700917 9.33602 0.774547 9.38614 0.855797 9.42047C0.937048 9.4548 1.0243 9.47266 1.11251 9.47303C1.20071 9.47266 1.28797 9.4548 1.36922 9.42047C1.45047 9.38614 1.5241 9.33602 1.58584 9.27303L5.35917 5.50637C5.42684 5.44394 5.48085 5.36817 5.51779 5.28384C5.55473 5.1995 5.5738 5.10843 5.5738 5.01637C5.5738 4.9243 5.55473 4.83323 5.51779 4.74889C5.48085 4.66456 5.42684 4.58879 5.35917 4.52637Z" fill="#A4BBDD"/>
                                                        </svg>
                                                    </div>
                                                </Link>
                                            </React.Fragment>
                                        )
                                    })}
                                    {expandCM &&
                                        <>
                                            {userData.forms && userData.forms.length > 0 &&
                                                <div className="cm-menu">
                                                    {userData.forms.map((form:any,index:number) => {
                                                        if (form.USR000) {
                                                            return (
                                                                <Link key={index} to="/users"
                                                                      className={`h-[19px] flex flex-col justify-center ${pageName === 'users' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.users')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.ORG000) {
                                                            return (
                                                                <>
                                                                    <Link key={index} to="/brokers"
                                                                          className={`h-[19px] flex flex-col justify-center ${pageName === 'brokers' ? 'text-primary-500' : ''}`}>
                                                                        {t('common:code_maintenance.brokers')}
                                                                    </Link>
                                                                    <Link key={index} to="/issuers"
                                                                          className={`h-[19px] flex flex-col justify-center ${pageName === 'issuers' ? 'text-primary-500' : ''}`}>
                                                                        {t('common:code_maintenance.issuers')}
                                                                    </Link>
                                                                    <Link key={index} to="/intermediaries"
                                                                          className={`h-[19px] flex flex-col justify-center ${pageName === 'intermediaries' ? 'text-primary-500' : ''}`}>
                                                                        {t('common:code_maintenance.intermediaries')}
                                                                    </Link>
                                                                </>
                                                            )
                                                        }
                                                        if (form.LQP000) {
                                                            return (
                                                                <Link key={index} to="/liquidity-providers" className={`h-[19px] flex flex-col justify-center ${pageName === 'liquidity-providers' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.liquidity_providers')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.APA000) {
                                                            return (
                                                                <Link key={index} to="/app-actions" className={`h-[19px] flex flex-col justify-center ${pageName === 'app-actions' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.app_actions')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.APF000) {
                                                            return (
                                                                <Link key={index} to="/app-forms" className={`h-[19px] flex flex-col justify-center ${pageName === 'app-forms' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.app_forms')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.APM000) {
                                                            return (
                                                                <Link key={index} to="/app-modules" className={`h-[19px] flex flex-col justify-center ${pageName === 'app-modules' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.app_modules')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.CUP000) {
                                                            return (
                                                                <Link key={index} to="/currency-pairs" className={`h-[19px] flex flex-col justify-center ${pageName === 'currency-pairs' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.currency_pairs')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.PYM000) {
                                                            return (
                                                                <Link key={index} to="/payment-methods" className={`h-[19px] flex flex-col justify-center ${pageName === 'payment-methods' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.payment_methods')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.SYS000) {
                                                            return (
                                                                <Link key={index} to="/system-config" className={`h-[19px] flex flex-col justify-center ${pageName === 'system-config' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.system_config')}
                                                                </Link>
                                                            )
                                                        }
                                                        if (form.ROL000) {
                                                            return (
                                                                <Link key={index} to="/user-roles" className={`h-[19px] flex flex-col justify-center ${pageName === 'user-roles' ? 'text-primary-500' : ''}`}>
                                                                    {t('common:code_maintenance.user_roles')}
                                                                </Link>
                                                            )
                                                        }
                                                    })}
                                                </div>
                                            }
                                        </>
                                    }
                                </div>
                            }

                            {/*COLLAPSE*/}
                            {userData && userData.apps.length > 0 &&
                                <>
                                    {menuCollapsed ?
                                        <Tooltip tooltipPosition="right" tooltipContent={t('common:dashboard_side_menu.expand')} childElementClassName="colExp">
                                            <button className="absolute bottom-0 w-full bg-white py-[16px] border-t-[1px] border-neutral-100 text-start colExp">
                                            <span onClick={()=>{
                                                const sel = (document as any).querySelector('.sidebar-nav').classList
                                                if (sel.contains('sidebar-collapse')){
                                                    sel.remove('sidebar-collapse')
                                                    localStorage.setItem("sidebarCollapsePref","false")
                                                    setMenuCollapsed(false)
                                                }else {
                                                    sel.add('sidebar-collapse')
                                                    localStorage.setItem("sidebarCollapsePref","true")
                                                    setMenuCollapsed(true)
                                                }
                                            }} className="sidebar-menu">
                                                <div className="caret-icon w-[24px] h-[24px] grid place-content-center menu-icon">
                                                    {/*icon-left*/}
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <g clipPath="url(#clip0_2783_149)">
                                                            <path d="M11.29 12L14.83 8.46001C15.0163 8.27265 15.1208 8.0192 15.1208 7.75501C15.1208 7.49082 15.0163 7.23737 14.83 7.05001C14.737 6.95628 14.6264 6.88189 14.5046 6.83112C14.3827 6.78035 14.252 6.75421 14.12 6.75421C13.988 6.75421 13.8573 6.78035 13.7354 6.83112C13.6136 6.88189 13.503 6.95628 13.41 7.05001L9.17 11.29C9.07628 11.383 9.00188 11.4936 8.95111 11.6154C8.90035 11.7373 8.87421 11.868 8.87421 12C8.87421 12.132 8.90035 12.2627 8.95111 12.3846C9.00188 12.5064 9.07628 12.617 9.17 12.71L13.41 17C13.5034 17.0927 13.6143 17.166 13.7361 17.2158C13.8579 17.2655 13.9884 17.2908 14.12 17.29C14.2516 17.2908 14.3821 17.2655 14.5039 17.2158C14.6257 17.166 14.7366 17.0927 14.83 17C15.0163 16.8126 15.1208 16.5592 15.1208 16.295C15.1208 16.0308 15.0163 15.7774 14.83 15.59L11.29 12Z" fill="#070B12"/>
                                                        </g>
                                                        <defs>
                                                            <clipPath id="clip0_2783_149">
                                                                <rect width="24" height="24" fill="white"/>
                                                            </clipPath>
                                                        </defs>
                                                    </svg>
                                                </div>
                                                <div className="flex justify-between items-center w-full menu-label">
                                                    <span className="side-menu-label">{t('common:dashboard_side_menu.collapse')}</span>
                                                </div>
                                            </span>
                                            </button>
                                        </Tooltip>
                                        :
                                        <button className="absolute bottom-0 w-full bg-white py-[16px] border-t-[1px] border-neutral-100 text-start">
                                        <span onClick={()=>{
                                            const sel = (document as any).querySelector('.sidebar-nav').classList
                                            if (sel.contains('sidebar-collapse')){
                                                sel.remove('sidebar-collapse')
                                                localStorage.setItem("sidebarCollapsePref","false")
                                                setMenuCollapsed(false)
                                            }else {
                                                sel.add('sidebar-collapse')
                                                localStorage.setItem("sidebarCollapsePref","true")
                                                setMenuCollapsed(true)
                                            }
                                        }} className="sidebar-menu">
                                            <div className="caret-icon w-[24px] h-[24px] grid place-content-center menu-icon">
                                                {/*icon-left*/}
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <g clipPath="url(#clip0_2783_149)">
                                                        <path d="M11.29 12L14.83 8.46001C15.0163 8.27265 15.1208 8.0192 15.1208 7.75501C15.1208 7.49082 15.0163 7.23737 14.83 7.05001C14.737 6.95628 14.6264 6.88189 14.5046 6.83112C14.3827 6.78035 14.252 6.75421 14.12 6.75421C13.988 6.75421 13.8573 6.78035 13.7354 6.83112C13.6136 6.88189 13.503 6.95628 13.41 7.05001L9.17 11.29C9.07628 11.383 9.00188 11.4936 8.95111 11.6154C8.90035 11.7373 8.87421 11.868 8.87421 12C8.87421 12.132 8.90035 12.2627 8.95111 12.3846C9.00188 12.5064 9.07628 12.617 9.17 12.71L13.41 17C13.5034 17.0927 13.6143 17.166 13.7361 17.2158C13.8579 17.2655 13.9884 17.2908 14.12 17.29C14.2516 17.2908 14.3821 17.2655 14.5039 17.2158C14.6257 17.166 14.7366 17.0927 14.83 17C15.0163 16.8126 15.1208 16.5592 15.1208 16.295C15.1208 16.0308 15.0163 15.7774 14.83 15.59L11.29 12Z" fill="#070B12"/>
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2783_149">
                                                            <rect width="24" height="24" fill="white"/>
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                            <div className="flex justify-between items-center w-full menu-label">
                                                <span className="side-menu-label">{t('common:dashboard_side_menu.collapse')}</span>
                                            </div>
                                        </span>
                                        </button>
                                    }
                                </>
                            }
                        </div>
                        <div className="m-[32px] min-w-[1100px] grow">
                            {children}
                        </div>
                    </div>
                    {profilePnl &&
                        <SidePanel closeFxn={()=>{
                            setProfilePnl(false)
                            setPwStep(1)
                            setProfilePnlTitle(t('common:dashboard.user_profile'))
                        }} backBtnArea={<BackButton disabled={view === 'profile' || pwStep === 2} onClick={()=>{
                            setView('profile')
                            setProfilePnlTitle(t('common:dashboard.user_profile'))
                        }}/>} title={pwStep !== 2 ? profilePnlTitle : ''}>
                            <ProfileContent userDets={userDets} stepFxn={(e)=>{setPwStep(e)}} step={pwStep} viewFxn={(e)=>{
                                setView(e)
                                setProfilePnlTitle(t('common:dashboard.change_password'))
                            }} view={view}/>
                        </SidePanel>
                    }

                    {ratesModal &&
                        <ExportPreviewModal title={t('common:provider_rates.valid_rates')} primaryAction={()=>{setRatesModal(false)}} primaryBtnText={t('common:general.close')} closeFxn={()=>{setRatesModal(false)}}>
                            <TablePrimary thead={[
                                {
                                    label:t('common:provider_rates.provider'),
                                    dataKey:'provider'
                                },
                                {
                                    label:t('common:provider_rates.rate'),
                                    dataKey:'rate'
                                },
                                {
                                    label:t('common:provider_rates.valid_from'),
                                    dataKey:'validFrom'
                                },
                                {
                                    label:t('common:provider_rates.valid_to'),
                                    dataKey:'validTo'
                                },
                                {
                                    label:t('common:provider_rates.updated_on'),
                                    dataKey:'updatedOn'
                                },
                            ]}
                                          paginatedItems={()=>{}}
                            >
                                {loadingProvRates &&
                                    <tr>
                                        <td colSpan={5}>
                                            <div className="flex justify-center items-center my-[24px]">
                                                <LoadingSpinner variant="primary"/>
                                            </div>
                                        </td>
                                    </tr>
                                }
                                {!loadingProvRates &&
                                    <>
                                        {provRates.length > 0 &&
                                            <>
                                                {provRates.map((rate:any,index:number) => (
                                                    <tr key={index}>
                                                        <td className="flex gap-[8px] items-center">
                                                            <img src={rate.lp_logos[24]} alt="provider logo"/>
                                                            <span>{rate.lip_name}</span>
                                                        </td>
                                                        <td>
                                                            <div className="flex items-center gap-[8px]">
                                                                <img src={getCurrFlag(rate.lpr_base_curr)}
                                                                     alt="base currency"/>
                                                                {rate.lpr_base_curr}
                                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path
                                                                        d="M17.9982 10.0002C17.9982 9.73497 17.8928 9.48062 17.7053 9.29308C17.5178 9.10554 17.2634 9.00019 16.9982 9.00019H5.40819L7.70819 6.71019C7.89649 6.52188 8.00228 6.26649 8.00228 6.00019C8.00228 5.73388 7.89649 5.47849 7.70819 5.29019C7.51988 5.10188 7.26449 4.99609 6.99819 4.99609C6.73188 4.99609 6.47649 5.10188 6.28819 5.29019L2.28819 9.29019C2.14943 9.43081 2.05543 9.60938 2.01806 9.80337C1.98068 9.99736 2.00161 10.1981 2.07819 10.3802C2.15321 10.5628 2.2806 10.7191 2.44432 10.8295C2.60804 10.9398 2.80076 10.9992 2.99819 11.0002H16.9982C17.2634 11.0002 17.5178 10.8948 17.7053 10.7073C17.8928 10.5198 17.9982 10.2654 17.9982 10.0002ZM21.9182 13.6202C21.8432 13.4376 21.7158 13.2812 21.552 13.1709C21.3883 13.0606 21.1956 13.0012 20.9982 13.0002H6.99819C6.73297 13.0002 6.47862 13.1055 6.29108 13.2931C6.10354 13.4806 5.99819 13.735 5.99819 14.0002C5.99819 14.2654 6.10354 14.5198 6.29108 14.7073C6.47862 14.8948 6.73297 15.0002 6.99819 15.0002H18.5882L16.2882 17.2902C16.1945 17.3831 16.1201 17.4937 16.0693 17.6156C16.0185 17.7375 15.9924 17.8682 15.9924 18.0002C15.9924 18.1322 16.0185 18.2629 16.0693 18.3848C16.1201 18.5066 16.1945 18.6172 16.2882 18.7102C16.3812 18.8039 16.4917 18.8783 16.6136 18.9291C16.7355 18.9798 16.8662 19.006 16.9982 19.006C17.1302 19.006 17.2609 18.9798 17.3828 18.9291C17.5046 18.8783 17.6152 18.8039 17.7082 18.7102L21.7082 14.7102C21.8469 14.5696 21.9409 14.391 21.9783 14.197C22.0157 14.003 21.9948 13.8023 21.9182 13.6202Z"
                                                                        fill="#BBCCE6"></path>
                                                                </svg>
                                                                <img src={getCurrFlag(rate.lpr_exch_curr)}
                                                                     alt="exchange currency"/>
                                                                {rate.lpr_exch_curr} {Number(rate.exch_buy_rate).toFixed(4)}
                                                            </div>
                                                        </td>
                                                        <td>{dayjs(rate.lpr_dt_from).locale(i18n.language).format('DD MMM YYYY, HH:mm')}</td>
                                                        <td>{dayjs(rate.lpr_dt_to).locale(i18n.language).format('DD MMM YYYY, HH:mm')}</td>
                                                        <td>{dayjs(rate.entered_date).locale(i18n.language).format('DD MMM YYYY, HH:mm')}</td>
                                                    </tr>
                                                ))}
                                            </>
                                        }
                                        {provRates.length === 0 &&
                                            <>
                                                <tr>
                                                    <td colSpan={5}>
                                                        <div className="flex flex-col justify-center items-center my-[24px]">
                                                            <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <g clipPath="url(#clip0_608_2355)">
                                                                    <path d="M39 6H25V14H39V6Z" fill="#E8EEF7"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M41.0007 6C41.0007 4.895 40.1047 4 39.0001 4H25.0001C23.8955 4 22.9995 4.895 22.9995 6V14C22.9995 15.105 23.8955 16 25.0001 16H39.0001C40.1047 16 41.0007 15.105 41.0007 14V6ZM27.0007 8V12H36.9995V8H27.0007Z" fill="#D2DDEE"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M49 56C49 54.896 49.896 54 51 54C52.104 54 53 54.896 53 56V58C53 59.105 52.105 60 51 60H13C11.895 60 11 59.105 11 58V10C11 8.895 11.895 8 13 8H25C26.105 8 27 8.895 27 10V12H37V10C37 8.895 37.895 8 39 8H51C52.105 8 53 8.895 53 10V48C53 49.104 52.104 50 51 50C49.896 50 49 49.104 49 48V12H41V14C41 15.105 40.105 16 39 16H25C23.895 16 23 15.105 23 14V12H15V56H49Z" fill="#D2DDEE"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 27H41C42.104 27 43 26.104 43 25C43 23.896 42.104 23 41 23H23C21.896 23 21 23.896 21 25C21 26.104 21.896 27 23 27Z" fill="#D2DDEE"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 38H41C42.104 38 43 37.104 43 36C43 34.896 42.104 34 41 34H23C21.896 34 21 34.896 21 36C21 37.104 21.896 38 23 38Z" fill="#D2DDEE"/>
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M23 49H41C42.104 49 43 48.104 43 47C43 45.896 42.104 45 41 45H23C21.896 45 21 45.896 21 47C21 48.104 21.896 49 23 49Z" fill="#D2DDEE"/>
                                                                </g>
                                                                <defs>
                                                                    <clipPath id="clip0_608_2355">
                                                                        <rect width="64" height="64" fill="white"/>
                                                                    </clipPath>
                                                                </defs>
                                                            </svg>
                                                            <p className={typography.paragraph2Regular}>No valid rates available.</p>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        }
                                    </>
                                }
                            </TablePrimary>
                        </ExportPreviewModal>
                    }
                </>
            }
        </>

    )
}

export default DashboardBaseLayout